import React, { useEffect, useState } from 'react';
import { themeButtons } from '../../../theme.styles';
import { useStyles } from '../cursos.styles-matui';
import { useGeneralStyles } from '../../../styles-matui';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Tooltip,
} from '@material-ui/core';
import { AdministradoresPermisosCursos } from '../cursos.interfaces';
import Chip from '@material-ui/core/Chip';
import { ThemeProvider } from '@material-ui/styles';
import { useQuery } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { GET_ALL_ADMINS_CURSOS } from '../../administradores/administradores.graphql';
import { Administrador } from '../../administradores/administradores.interfaces';
import {
    PERMISOS_ADMINISTRADOR_CURSO,
    VACIO,
    ROLES_USUARIOS,
    ALL_PERMISOS_ADMINISTRADOR_CURSO,
} from '../../../constants';
import { useSelector } from 'react-redux';
import '../nuevo-curso/nuevo-curso.sass';
import { Reducers } from '../../../store';

export default function AsignarAdministrador(props) {
    const classes = useStyles();
    const generalClassses = useGeneralStyles();
    const user = useSelector<Reducers>(
        (reducers) => reducers.loginReducer,
    ) as Administrador;
    const queryAdmins = useQuery(GET_ALL_ADMINS_CURSOS);
    const [rootAdminCourse] = useState<AdministradoresPermisosCursos>({
        idAdministrador: user.idAdministrador,
        nombreCompleto: user.nombreCompleto,
        permisos: [],
        edit: false,
    });
    const [adminSelected, setAdminSelected] =
        useState<AdministradoresPermisosCursos>({
            idAdministrador: '',
            nombreCompleto: '',
            permisos: [],
            edit: false,
        });

    useEffect(() => {
        if (queryAdmins.data) {
            const adminsCurso: any[] = [];
            queryAdmins.data.getAdmins.forEach((admin: any) => {
                if (admin.cursos !== null && admin.cursos !== undefined) {
                    const curso = admin.cursos.find(
                        (curso) => curso.idCurso === props.idCurso,
                    );

                    if (curso !== null && curso !== undefined) {
                        adminsCurso.push({
                            idAdministrador: admin.idAdministrador,
                            nombreCompleto: admin.nombreCompleto,
                            permisos: curso.permisos,
                            edit: false,
                        });
                    }
                }
            });

            if (adminsCurso.length > 0) {
                props.setAdminsCourse([...adminsCurso]);
            } else {
                props.setAdminsCourse([
                    {
                        idAdministrador: user.idAdministrador,
                        nombreCompleto: user.nombreCompleto,
                        permisos: ALL_PERMISOS_ADMINISTRADOR_CURSO,
                        edit: false,
                    },
                ]);
            }
        }
    }, [queryAdmins.data]);

    if (queryAdmins.loading) {
        return (
            <div>
                <CircularProgress color='secondary' />
            </div>
        );
    } else if (queryAdmins.error) {
        return (
            <div>
                <Typography>
                    <b>Error al cargar administradores</b>
                </Typography>
            </div>
        );
    }

    const handleChangeAdministrador = (
        event: React.ChangeEvent<{ value: unknown }>,
    ) => {
        const nombreAdmin = event.target.value as string;
        const adminPreviousllySelected = props.adminsCourse.find(
            (admin) => admin.nombreCompleto === nombreAdmin,
        ) as AdministradoresPermisosCursos;

        if (adminPreviousllySelected) {
            setAdminSelected(adminPreviousllySelected);
        } else {
            const admin = queryAdmins.data.getAdmins.find(
                (admin: Administrador) => admin.nombreCompleto === nombreAdmin,
            ) as Administrador;
            const newAdminSelected = {
                idAdministrador: admin.idAdministrador,
                nombreCompleto: admin.nombreCompleto,
                permisos: [],
                edit: false,
            };
            setAdminSelected(newAdminSelected);
        }
    };

    const handleChanguepermisos = (
        event: React.ChangeEvent<HTMLInputElement>,
        permiso: string,
    ) => {
        // If permission was checked then permission
        // must be added to current admin selected
        if (event.target.checked) {
            setAdminSelected((prev) => ({
                ...prev,
                permisos: [...prev.permisos, permiso],
            }));
        } else {
            // if permission was un checked then permission
            // must be removed to current admin selected
            setAdminSelected((prev) => ({
                ...prev,
                permisos: prev.permisos.filter((perm) => perm !== permiso),
            }));
        }
    };

    const handleAddPermisosAdministrador = () => {
        const adminFound = props.adminsCourse.find(
            (adm) => adm.idAdministrador === adminSelected.idAdministrador,
        );

        if (adminFound) {
            props.setAdminsCourse((prev) =>
                prev.map((adm) =>
                    adm.idAdministrador === adminFound.idAdministrador
                        ? {
                              ...adminFound,
                              ...adminSelected,
                          }
                        : adm,
                ),
            );
        } else {
            props.setAdminsCourse((prev) => [...prev, adminSelected]);
        }
    };

    const handleDeleteConfiguracionAdminPermisos = (adminId: string) => {
        props.setAdminsCourse((prev) =>
            prev.filter((admin) => admin.idAdministrador !== adminId),
        );

        if (adminSelected.idAdministrador === adminId) {
            setAdminSelected((prev) => ({
                ...prev,
                permisos: [],
            }));
        }
    };

    const getUserCoursePermissions = () => {
        const zero = 0;
        const userCourses = user.cursos.filter(
            (curso) => curso.idCurso === props.idCurso,
        );
        if (userCourses.length) {
            return userCourses[zero].permisos;
        }

        const admin = props.adminsCourse.find(
            (adm) => adm.idAdministrador === user.idAdministrador,
        );
        if (admin) {
            return admin.permisos;
        }

        return [];
    };

    const isAllowedToDoByPermissions = (admin) => {
        const userPermissions = getUserCoursePermissions();
        return (
            admin.idAdministrador !== user.idAdministrador &&
            userPermissions.includes('PERADMCUR06')
        );
    };

    return (
        <div className='AccordionDetailsParent'>
            <div className='containerAdminschild'>
                <FormControl
                    id='tipe'
                    variant='outlined'
                    className={generalClassses.formControl}
                >
                    <InputLabel id='InputLabel-outlined-label-admins'>
                        Administrador
                    </InputLabel>
                    <Select
                        labelId='select-outlined-admins'
                        id='select-outlined-admins'
                        value={adminSelected.nombreCompleto}
                        onChange={handleChangeAdministrador}
                        label='Administrador'
                    >
                        {queryAdmins.data.getAdmins
                            .filter((admin: Administrador) =>
                                user.tipo === ROLES_USUARIOS.SADM
                                    ? admin
                                    : admin.tipo === ROLES_USUARIOS.ADMN,
                            )
                            .map((admin: Administrador) => (
                                <MenuItem
                                    key={admin.idAdministrador}
                                    className={generalClassses.menuItem}
                                    value={admin.nombreCompleto}
                                >
                                    {admin.nombreCompleto}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>

                <div
                    className={
                        rootAdminCourse.nombreCompleto === VACIO
                            ? classes.displayNone
                            : classes.display
                    }
                >
                    <FormControl component='fieldset'>
                        <FormGroup>
                            {Object.keys(PERMISOS_ADMINISTRADOR_CURSO).map(
                                (key) => (
                                    <FormControlLabel
                                        className={classes.checkbox}
                                        key={key}
                                        control={
                                            <Checkbox
                                                checked={adminSelected.permisos.some(
                                                    (per) => per === key,
                                                )}
                                                onChange={(e) =>
                                                    handleChanguepermisos(
                                                        e,
                                                        key,
                                                    )
                                                }
                                                name={
                                                    PERMISOS_ADMINISTRADOR_CURSO[
                                                        key
                                                    ]
                                                }
                                            />
                                        }
                                        label={
                                            PERMISOS_ADMINISTRADOR_CURSO[key]
                                        }
                                    />
                                ),
                            )}
                        </FormGroup>
                    </FormControl>

                    <div className='containerbtnAddnewAdminPermisos'>
                        <ThemeProvider theme={themeButtons}>
                            <Button
                                className={classes.btnAddConfigAdminPermisos}
                                onClick={handleAddPermisosAdministrador}
                                color='secondary'
                                variant='contained'
                                disabled={
                                    !adminSelected.idAdministrador ||
                                    !isAllowedToDoByPermissions(adminSelected)
                                }
                            >
                                AGREGAR ADMINISTRADOR
                            </Button>
                        </ThemeProvider>
                    </div>
                </div>
            </div>

            <div className='containerAdminschild'>
                <Typography
                    className={classes.title}
                    variant='h6'
                    id='titleAdmins'
                    component='div'
                >
                    Administradores asignados:
                </Typography>

                {props.adminsCourse.map((admin, indA) => (
                    <Tooltip
                        key={indA}
                        title={
                            isAllowedToDoByPermissions(admin)
                                ? ''
                                : 'No puedes editar los permisos de este administrador'
                        }
                    >
                        <div>
                            {isAllowedToDoByPermissions(admin) ? (
                                <Chip
                                    label={admin.nombreCompleto}
                                    className={classes.chipVideo}
                                    onClick={() => setAdminSelected(admin)}
                                    onDelete={() =>
                                        handleDeleteConfiguracionAdminPermisos(
                                            admin.idAdministrador,
                                        )
                                    }
                                    color='secondary'
                                />
                            ) : (
                                <Chip
                                    label={admin.nombreCompleto}
                                    className={classes.chipVideo}
                                    onClick={() => setAdminSelected(admin)}
                                    color='secondary'
                                />
                            )}
                        </div>
                    </Tooltip>
                ))}
            </div>
        </div>
    );
}
