import React, { useState, useEffect, useCallback } from 'react';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { useStyles } from './cursos.styles-matui';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { ButtonGroup, Button } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import ModalConfirmaciones from '../modal-confirmaciones/modal-confirmaciones.compt';
import { DataControlModalParent } from '../../interfaces';
import {
    STATUS,
    VACIO,
    ROLES_USUARIOS,
    NAME_COOKIE_ADMINS,
} from '../../constants';
import { useLazyQuery, useMutation } from '@apollo/client';
import Spinner from '../spinner/spinner.compt';
import { useGeneralStyles, useGeneralToolbarStyles } from '../../styles-matui';
import Snackbars from '../snackbar/snackbar.compt';
import Avatar from '@material-ui/core/Avatar';
import {
    GET_ALL_COURSES,
    DELETE_CURSO_GQL,
    HAB_CURSO_GQL,
    INHAB_CURSO_GQL,
} from './cursos.graphql';
import { useSelector } from 'react-redux';
import {
    Curso,
    HeadCellCurso,
    EnhancedTableProps,
    Order,
    PropsEnhancedTableToolbar,
} from './cursos.interfaces';
import EditIcon from '@material-ui/icons/Edit';
import Regresar from '../regresar/regresar.compt';
import { Administrador } from '../administradores/administradores.interfaces';
import { LinearProgress } from '@material-ui/core';
import { Reducers } from '../../store';
import { getDataLogin } from '../login/login.service';

const HeadCellAdmins: HeadCellCurso[] = [
    { id: 'nombre', numeric: false, disablePadding: true, label: 'Nombre' },
    { id: 'tipo', numeric: false, disablePadding: false, label: 'Categoría' },
    {
        id: 'cantAdministradores',
        numeric: false,
        disablePadding: false,
        label: 'Administradores',
    },
    {
        id: 'cantUsuarios',
        numeric: true,
        disablePadding: false,
        label: 'Usuarios',
    },
    // {
    //     id: 'duracion',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'Duración',
    // },
    {
        id: 'banInactivo',
        numeric: true,
        disablePadding: false,
        label: 'Estatus',
    },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<Curso>(
    array: Curso[],
    comparator: (a: any, b: any) => number,
) {
    const stabilizedThis = array.map(
        (el, index) => [el, index] as [any, number],
    );
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
    const {
        classes,
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler =
        (property: keyof Curso) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding='checkbox'>
                    <Checkbox
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>

                <TableCell align='left'>Foto</TableCell>

                {HeadCellAdmins.map((HeadCellAdmin) => (
                    <TableCell
                        key={HeadCellAdmin.id}
                        align='left'
                        sortDirection={
                            orderBy === HeadCellAdmin.id ? order : false
                        }
                    >
                        <TableSortLabel
                            active={orderBy === HeadCellAdmin.id}
                            direction={
                                orderBy === HeadCellAdmin.id ? order : 'asc'
                            }
                            onClick={createSortHandler(HeadCellAdmin.id)}
                        >
                            {HeadCellAdmin.label}
                            {orderBy === HeadCellAdmin.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}

                <TableCell align='center'>Acciones</TableCell>
            </TableRow>
        </TableHead>
    );
};

const EnhancedTableToolbar = (props: PropsEnhancedTableToolbar) => {
    const classes = useGeneralToolbarStyles();
    const [inhabilitarCurso] = useMutation(INHAB_CURSO_GQL);
    const [deleteCurso] = useMutation(DELETE_CURSO_GQL);

    const inhabCurso = (idCurso: string) => {
        return new Promise((resolve, reject) => {
            inhabilitarCurso({ variables: { idCurso } })
                .then(() => resolve(true))
                .catch((error) => reject(error));
        });
    };

    const eliminarCurso = (idCurso: string): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            deleteCurso({ variables: { idCurso } })
                .then(() => resolve(true))
                .catch((error) => reject(error));
        });
    };

    const deleteAny = () => {
        const usersAllowed = props.cursosSelected.filter(
            (curso) =>
                !props.cursos.find((item) => item.idCurso === curso)
                    .banInactivo,
        );

        if (usersAllowed.length === 0) {
            props.openSnackBar(
                `No hay cursos disponibles para eliminar`,
                STATUS.error,
            );
        } else {
            props.setOpenSpinner(true);
            const promises = usersAllowed.map((userA) => eliminarCurso(userA));

            Promise.all(promises)
                .then(() => {
                    props.setOpenSpinner(false);
                    props.setSelected([]);
                    props.refreshCourses('Exito al eliminar los cursos');
                })
                .catch(() => {
                    props.setOpenSpinner(false);
                    props.openSnackBar(
                        `Error al eliminar los cursos`,
                        STATUS.error,
                    );
                });
        }
    };

    const inhabAny = () => {
        const usersAllowed = props.cursosSelected.filter(
            (curso) =>
                !props.cursos.find((item) => item.idCurso === curso)
                    .banInactivo,
        );

        if (usersAllowed.length === 0) {
            props.openSnackBar(
                `No hay cursos disponibles para inhabilitar`,
                STATUS.error,
            );
        } else {
            props.setOpenSpinner(true);
            const promises = usersAllowed.map((userA) => inhabCurso(userA));

            Promise.all(promises)
                .then(() => {
                    props.setOpenSpinner(false);
                    props.setSelected([]);
                    props.refreshCourses('Exito al inhabilitar los cursos');
                })
                .catch(() => {
                    props.setOpenSpinner(false);
                    props.openSnackBar(
                        `Error al inhabilitar los cursos`,
                        STATUS.error,
                    );
                });
        }
    };

    return (
        <Toolbar className={classes.root}>
            <Typography
                className={classes.title}
                variant='h6'
                id='tableTitle'
                component='div'
                color='primary'
            >
                Lista de cursos
            </Typography>

            <ButtonGroup
                className={classes.todosButtonGroup}
                color='primary'
                aria-label='outlined primary button group'
            >
                <Button
                    onClick={inhabAny}
                    className={clsx(classes.todosActionButtons, {
                        [classes.todosActionButtonsOcult]:
                            props.cursosSelected.length === 0,
                    })}
                >
                    <BlockIcon />
                </Button>
                <Button
                    onClick={deleteAny}
                    className={clsx(classes.todosActionButtons, {
                        [classes.todosActionButtonsOcult]:
                            props.cursosSelected.length === 0,
                    })}
                >
                    <DeleteIcon />
                </Button>
            </ButtonGroup>
        </Toolbar>
    );
};

function NuevoCursoFab(props) {
    const classes = useGeneralStyles();

    return (
        <Fab
            color='primary'
            aria-label='addAdmin'
            className={classes.fab}
            onClick={props.goToNewCourse}
        >
            <AddIcon />
        </Fab>
    );
}

export default function Cursos() {
    const classes = useStyles();
    const generalClasses = useGeneralStyles();
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof Curso>('nombre');
    const [selected, setSelected] = useState<string[]>([]);
    const [deleteCurso] = useMutation(DELETE_CURSO_GQL);
    const [inhabilitarCurso] = useMutation(INHAB_CURSO_GQL);
    const [habilitarCurso] = useMutation(HAB_CURSO_GQL);
    const [openSpinner, setOpenSpinner] = useState(false);
    const [snackbar, setSnackbar] = useState<DataControlModalParent>({
        open: false,
    });
    const [confirmaciones, setConfirmaciones] =
        useState<DataControlModalParent>({ open: false, descripcion: '' });
    const [fetchCourses, queryCourses] = useLazyQuery(GET_ALL_COURSES);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const navigate = useNavigate();
    const adminData: any = getDataLogin(NAME_COOKIE_ADMINS);
    const user = JSON.parse(adminData) as Administrador;
    const [permisosUsuario] = useState<any[]>(
        user.cursos && user.cursos !== null ? user.cursos : [],
    );
    const [courses, setCourses] = useState<Array<Curso>>([]);

    const getCourses = useCallback(async () => {
        await fetchCourses().then((response) =>
            setCourses(response.data.getCursos),
        );
    }, []);

    useEffect(() => {
        getCourses();
    }, [getCourses]);

    const actionsNewCourse = (idCurso?: string) => {
        sessionStorage.removeItem('generalData');
        sessionStorage.removeItem('modulosData');
        sessionStorage.removeItem('usuariosControl');
        sessionStorage.removeItem('administradoresControl');
        if (idCurso) {
            navigate(`/ccap/admin/nuevo-curso?idCursoSelected=${idCurso}`);
        } else {
            navigate('/ccap/admin/nuevo-curso');
        }
    };

    const goToEditCourse = (idCurso: string) => {
        actionsNewCourse(idCurso);
    };

    const goToNewCourse = () => {
        actionsNewCourse();
    };

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Curso,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        if (event.target.checked) {
            const newSelecteds = courses.map((n: any) => n.idCurso);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, idCurso: string) => {
        const selectedIndex = selected.indexOf(idCurso);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, idCurso);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const closeSnackbar = () => {
        setSnackbar({ open: false, closeParent: closeSnackbar });
    };

    const openSnackBar = (mensaje: string, status: string) => {
        setSnackbar({
            status,
            open: true,
            descripcion: mensaje,
            closeParent: closeSnackbar,
        });
    };

    const closeModalConfirmaciones = (
        value: boolean,
        accion: string,
        idCurso: string,
    ) => {
        setConfirmaciones({ open: false, descripcion: '' });
        const cursoSelect = courses.find(
            (cur) => cur.idCurso === idCurso,
        ) as Curso;

        if (value && accion === 'inhabilitar') {
            setOpenSpinner(true);
            inhabilitarCurso({ variables: { idCurso } })
                .then(() => {
                    setOpenSpinner(false);
                    refreshCourses(
                        `Exito al inhabilitar el curso ${cursoSelect.nombre}`,
                    );
                })
                .catch(() => {
                    openSnackBar(
                        `Error al inhabilitar el curso ${cursoSelect.nombre}`,
                        STATUS.error,
                    );
                    setOpenSpinner(false);
                });
        } else if (value && accion === 'habilitar') {
            setOpenSpinner(true);
            habilitarCurso({ variables: { idCurso } })
                .then(() => {
                    setOpenSpinner(false);
                    refreshCourses(
                        `Exito al habilitar el curso ${cursoSelect.nombre}`,
                    );
                })
                .catch(() => {
                    openSnackBar(
                        `Error al habilitar el curso ${cursoSelect.nombre}`,
                        STATUS.error,
                    );
                    setOpenSpinner(false);
                });
        } else if (value && accion === 'eliminar') {
            setOpenSpinner(true);
            deleteCurso({ variables: { idCurso } })
                .then(() => {
                    setOpenSpinner(false);
                    refreshCourses(
                        `Exito al eliminar el curso ${cursoSelect.nombre}`,
                    );
                })
                .catch(() => {
                    openSnackBar(
                        `Error al eliminar el curso ${cursoSelect.nombre}`,
                        STATUS.error,
                    );
                    setOpenSpinner(false);
                });
        }
    };

    const handleModalConfirmacionesInhabilitar = (idCurso: string) => {
        const cursoSelect = courses.find(
            (admin) => admin.idCurso === idCurso,
        ) as Curso;
        setConfirmaciones({
            open: true,
            descripcion: `¿Estas seguro de ${
                cursoSelect.banInactivo ? 'habilitar' : 'inhabilitar'
            } el curso ${cursoSelect.nombre}?`,
            closeParent: closeModalConfirmaciones,
            accion: cursoSelect.banInactivo ? 'habilitar' : 'inhabilitar',
            uid: idCurso,
        });
    };

    const handleModalConfirmacionesEliminar = (idCurso: string) => {
        const cursoSelect = courses.find(
            (admin) => admin.idCurso === idCurso,
        ) as Curso;
        setConfirmaciones({
            open: true,
            descripcion: `¿Estas seguro de eliminar el curso ${cursoSelect.nombre}?`,
            closeParent: closeModalConfirmaciones,
            accion: 'eliminar',
            uid: idCurso,
        });
    };

    const isSelected = (idCurso: string) => selected.indexOf(idCurso) !== -1;

    if (queryCourses.loading) {
        return <LinearProgress color='secondary' />;
    }

    if (queryCourses.error) {
        openSnackBar('No se ha podido cargar el contenido', STATUS.error);
    }

    const refreshCourses = async (message: string) => {
        await fetchCourses()
            .then((response) => {
                setCourses(response.data.getCursos);
                openSnackBar(message, STATUS.success);
            })
            .catch(() => {
                openSnackBar(
                    'No se ha podido actualizar, intenta nuevamente',
                    STATUS.success,
                );
            });
    };

    return (
        <div className={classes.root}>
            <Regresar route='/ccap/admin/menu' />
            {courses.length === 0 ? (
                <div className={classes.titleNoCourses}>
                    <Typography component='h4'>
                        <b>No tienes cursos para administrar aún</b>
                    </Typography>
                </div>
            ) : (
                <Paper className={classes.paper}>
                    <EnhancedTableToolbar
                        course={VACIO}
                        setSelected={setSelected}
                        cursos={courses}
                        cursosSelected={selected}
                        openSnackBar={openSnackBar}
                        setOpenSpinner={setOpenSpinner}
                        refreshCourses={refreshCourses}
                    />

                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby='tableTitle'
                            size='medium'
                            aria-label='enhanced table'
                        >
                            <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={courses.length}
                            />

                            <TableBody>
                                {stableSort(
                                    courses,
                                    getComparator(order, orderBy),
                                )
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage,
                                    )
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(
                                            row.idCurso,
                                        );
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                className={
                                                    row.banInactivo === true
                                                        ? generalClasses.tableRowDisable
                                                        : ''
                                                }
                                                hover
                                                role='checkbox'
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.idCurso}
                                                selected={isItemSelected}
                                            >
                                                <TableCell padding='checkbox'>
                                                    <Checkbox
                                                        onClick={(event) =>
                                                            handleClick(
                                                                event,
                                                                row.idCurso,
                                                            )
                                                        }
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            'aria-labelledby':
                                                                labelId,
                                                        }}
                                                    />
                                                </TableCell>

                                                <TableCell align='center'>
                                                    <Avatar
                                                        alt={row.nombre}
                                                        src={row.foto}
                                                    />
                                                </TableCell>

                                                <TableCell
                                                    component='th'
                                                    id={labelId}
                                                    scope='row'
                                                    onClick={(event) =>
                                                        handleClick(
                                                            event,
                                                            row.idCurso,
                                                        )
                                                    }
                                                >
                                                    {row.nombre}
                                                </TableCell>

                                                <TableCell
                                                    onClick={(event) =>
                                                        handleClick(
                                                            event,
                                                            row.idCurso,
                                                        )
                                                    }
                                                    align='left'
                                                >
                                                    {row.tipo?.toUpperCase()}
                                                </TableCell>
                                                <TableCell
                                                    onClick={(event) =>
                                                        handleClick(
                                                            event,
                                                            row.idCurso,
                                                        )
                                                    }
                                                    align='left'
                                                >
                                                    {row.cantAdministradores}
                                                </TableCell>
                                                <TableCell
                                                    onClick={(event) =>
                                                        handleClick(
                                                            event,
                                                            row.idCurso,
                                                        )
                                                    }
                                                    align='left'
                                                >
                                                    {row.cantUsuarios}
                                                </TableCell>
                                                {/* <TableCell
                                                    onClick={(event) =>
                                                        handleClick(
                                                            event,
                                                            row.idCurso,
                                                        )
                                                    }
                                                    align='left'
                                                >
                                                    {row.duracion}
                                                </TableCell> */}
                                                <TableCell
                                                    onClick={(event) =>
                                                        handleClick(
                                                            event,
                                                            row.idCurso,
                                                        )
                                                    }
                                                    align='left'
                                                >
                                                    <b>
                                                        {row.banInactivo
                                                            ? 'Inactivo'
                                                            : 'Activo'}
                                                    </b>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <ButtonGroup
                                                        color='primary'
                                                        aria-label='outlined primary button group'
                                                    >
                                                        <Button
                                                            id='btnEditarContenido'
                                                            disabled={
                                                                user.tipo ===
                                                                ROLES_USUARIOS.SADM
                                                                    ? false
                                                                    : permisosUsuario.length ===
                                                                        0
                                                                      ? true
                                                                      : permisosUsuario.filter(
                                                                              (
                                                                                  cur,
                                                                              ) =>
                                                                                  cur.idCurso ===
                                                                                  row.idCurso,
                                                                          )
                                                                              .length ===
                                                                          0
                                                                        ? true
                                                                        : permisosUsuario
                                                                              .filter(
                                                                                  (
                                                                                      cur,
                                                                                  ) =>
                                                                                      cur.idCurso ===
                                                                                      row.idCurso,
                                                                              )[0]
                                                                              .permisos.find(
                                                                                  (
                                                                                      per,
                                                                                  ) =>
                                                                                      per ===
                                                                                      'PERADMCUR01',
                                                                              ) ===
                                                                          undefined
                                                            }
                                                            onClick={() =>
                                                                goToEditCourse(
                                                                    row.idCurso,
                                                                )
                                                            }
                                                            className={
                                                                classes.actionButtons
                                                            }
                                                        >
                                                            <Tooltip title='Editar contenido'>
                                                                <EditIcon />
                                                            </Tooltip>
                                                        </Button>

                                                        <Button
                                                            id='btnInhabilitarCurso'
                                                            disabled={
                                                                user.tipo ===
                                                                ROLES_USUARIOS.SADM
                                                                    ? false
                                                                    : permisosUsuario.length ===
                                                                        0
                                                                      ? true
                                                                      : permisosUsuario.filter(
                                                                              (
                                                                                  cur,
                                                                              ) =>
                                                                                  cur.idCurso ===
                                                                                  row.idCurso,
                                                                          )
                                                                              .length ===
                                                                          0
                                                                        ? true
                                                                        : permisosUsuario
                                                                              .filter(
                                                                                  (
                                                                                      cur,
                                                                                  ) =>
                                                                                      cur.idCurso ===
                                                                                      row.idCurso,
                                                                              )[0]
                                                                              .permisos.find(
                                                                                  (
                                                                                      per,
                                                                                  ) =>
                                                                                      per ===
                                                                                      'PERADMCUR03',
                                                                              ) ===
                                                                          undefined
                                                            }
                                                            onClick={() =>
                                                                handleModalConfirmacionesInhabilitar(
                                                                    row.idCurso,
                                                                )
                                                            }
                                                            className={
                                                                classes.actionButtons
                                                            }
                                                        >
                                                            <Tooltip
                                                                title={
                                                                    row.banInactivo
                                                                        ? 'Habilitar'
                                                                        : 'Inhabilitar'
                                                                }
                                                            >
                                                                <BlockIcon />
                                                            </Tooltip>
                                                        </Button>

                                                        <Button
                                                            id='btnEliminarCurso'
                                                            disabled={
                                                                user.tipo ===
                                                                ROLES_USUARIOS.SADM
                                                                    ? false
                                                                    : permisosUsuario.length ===
                                                                        0
                                                                      ? true
                                                                      : permisosUsuario.filter(
                                                                              (
                                                                                  cur,
                                                                              ) =>
                                                                                  cur.idCurso ===
                                                                                  row.idCurso,
                                                                          )
                                                                              .length ===
                                                                          0
                                                                        ? true
                                                                        : permisosUsuario
                                                                              .filter(
                                                                                  (
                                                                                      cur,
                                                                                  ) =>
                                                                                      cur.idCurso ===
                                                                                      row.idCurso,
                                                                              )[0]
                                                                              .permisos.find(
                                                                                  (
                                                                                      per,
                                                                                  ) =>
                                                                                      per ===
                                                                                      'PERADMCUR02',
                                                                              ) ===
                                                                          undefined
                                                            }
                                                            onClick={() =>
                                                                handleModalConfirmacionesEliminar(
                                                                    row.idCurso,
                                                                )
                                                            }
                                                            className={
                                                                classes.actionButtons
                                                            }
                                                        >
                                                            <Tooltip title='Eliminar'>
                                                                <DeleteIcon />
                                                            </Tooltip>
                                                        </Button>
                                                    </ButtonGroup>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        labelRowsPerPage='Resultados por página'
                        count={courses.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            )}

            <Spinner
                class={openSpinner ? 'displaySpinner' : 'displayNoneSpinner'}
            />
            <ModalConfirmaciones options={confirmaciones} />
            <Snackbars options={snackbar} />
            <div
                className={
                    user.permisos.find((per) => per === 'PERADM03') ===
                    undefined
                        ? classes.displayNone
                        : VACIO
                }
            >
                <NuevoCursoFab goToNewCourse={goToNewCourse} />
            </div>
        </div>
    );
}
